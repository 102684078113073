
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/hooks/TranslateService";
import { IUser } from "@/store/modules/valored/UserModule";
import {
  ICompany,
  ICompanyYearlyParams,
} from "@/store/modules/valored/CompanyModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "account-overview",
  components: {},
  setup() {
    const store = useStore();
    const accountInfo = reactive({
      userProfile: computed(() => store.getters.currentUser as IUser),
      companyProfile: computed(
        () => store.getters.getCompanyProfile as ICompany
      ),
      companyYearlyParams: computed(
        () => store.getters.getCompanyYearlyParams as ICompanyYearlyParams
      ),
    });

    const isAdmin = computed(() => store.getters.isAdmin);

    const statusAssociatedText = ref(
      computed(() => {
        return store.getters.getCompanyYearlyParams.azienda_yearly_parameters
          .is_associated
          ? translate("CHANGE_ASSOCIATED_OPTION")
          : translate("CHANGE_NOT_ASSOCIATED_OPTION");
      })
    );
    const statusQuotedText = ref(
      computed(() => {
        return store.getters.getCompanyYearlyParams.azienda_yearly_parameters
          .is_quoted
          ? translate("CHANGE_QUOTED_OPTION")
          : translate("CHANGE_NOT_QUOTED_OPTION");
      })
    );

    onMounted(() => {
      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          localStorage.getItem("to_year")
        );
      }, 1000);

      if (!accountInfo.companyYearlyParams) {
        store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS);
      }
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    return {
      translate,
      ...toRefs(accountInfo),
      statusAssociatedText,
      statusQuotedText,
      isAdmin
    };
  },
});
