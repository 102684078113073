import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = {
  class: "card-header cursor-pointer",
  "data-bs-target": "#kt_profile_company_view"
}
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = {
  class: "card-body p-9 show",
  id: "kt_profile_company_view"
}
const _hoisted_6 = { class: "row mb-7" }
const _hoisted_7 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_8 = { class: "col-lg-8 fv-row" }
const _hoisted_9 = { class: "fw-bold fs-6" }
const _hoisted_10 = { class: "row mb-7" }
const _hoisted_11 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_12 = { class: "col-lg-8 fv-row" }
const _hoisted_13 = { class: "fw-bold fs-6" }
const _hoisted_14 = { class: "row mb-7" }
const _hoisted_15 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_16 = { class: "col-lg-8 fv-row" }
const _hoisted_17 = {
  key: 0,
  class: "fw-bold fs-6"
}
const _hoisted_18 = { class: "row mb-7" }
const _hoisted_19 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_20 = { class: "col-lg-8 fv-row" }
const _hoisted_21 = { class: "fw-bold fs-6" }
const _hoisted_22 = { class: "row mb-7" }
const _hoisted_23 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_24 = { class: "col-lg-8 fv-row" }
const _hoisted_25 = { class: "fw-bold fs-6" }
const _hoisted_26 = { class: "row mb-7" }
const _hoisted_27 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_28 = { class: "col-lg-8 fv-row" }
const _hoisted_29 = { class: "fw-bold fs-6" }
const _hoisted_30 = { class: "row mb-7" }
const _hoisted_31 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_32 = { class: "col-lg-8 fv-row" }
const _hoisted_33 = {
  key: 0,
  class: "fw-bold fs-6"
}
const _hoisted_34 = { class: "row mb-7" }
const _hoisted_35 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_36 = { class: "col-lg-8 fv-row" }
const _hoisted_37 = { class: "fw-bold fs-6" }
const _hoisted_38 = { class: "card mb-5 mb-xl-10" }
const _hoisted_39 = {
  class: "card-header cursor-pointer",
  "data-bs-target": "#kt_profile_account_view"
}
const _hoisted_40 = { class: "card-title m-0" }
const _hoisted_41 = { class: "fw-bolder m-0" }
const _hoisted_42 = {
  class: "card-body p-9 show",
  id: "kt_profile_account_view"
}
const _hoisted_43 = { class: "row mb-7" }
const _hoisted_44 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_45 = { class: "col-lg-8 fv-row" }
const _hoisted_46 = { class: "fw-bold fs-6" }
const _hoisted_47 = { class: "row mb-7" }
const _hoisted_48 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_49 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_50 = { class: "fw-bolder fs-6 me-2" }
const _hoisted_51 = { class: "row mb-7" }
const _hoisted_52 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_53 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_54 = { class: "fw-bolder fs-6 me-2" }
const _hoisted_55 = { class: "row mb-7" }
const _hoisted_56 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_57 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_58 = { class: "fw-bolder fs-6 me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isAdmin && _ctx.companyYearlyParams)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate("AZIENDA_DETAIL_SEC_HEADER")), 1)
            ]),
            _createVNode(_component_router_link, {
              to: "/pages/account/company-settings",
              class: "btn btn-dark-blue align-self-center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("HEADER_EDIT_AZIENDA_SETTINGS_BUTTON")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate("AZIENDA_DENOM_STRING")), 1),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.companyProfile.denom), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.translate("AZIENDA_P_IVA_STRING")), 1),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.companyProfile.p_iva), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.translate("AZIENDA_SECTOR_STRING")) + " (" + _toDisplayString(_ctx.companyYearlyParams.azienda_yearly_parameters.year) + ") ", 1),
              _createElementVNode("div", _hoisted_16, [
                (_ctx.companyYearlyParams.settore)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.companyYearlyParams.settore.description), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.translate("AZIENDA_REVENUE_STRING")) + " (" + _toDisplayString(_ctx.companyYearlyParams.azienda_yearly_parameters.year) + ") ", 1),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.companyYearlyParams.fatturato.descrizione), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.translate("AZIENDA_IS_QUOTED_STRING")) + " (" + _toDisplayString(_ctx.companyYearlyParams.azienda_yearly_parameters.year) + ") ", 1),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.statusQuotedText), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.translate("AZIENDA_IS_ASSOCIATED_STRING")) + " (" + _toDisplayString(_ctx.companyYearlyParams.azienda_yearly_parameters.year) + ")", 1),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.statusAssociatedText), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.translate("AZIENDA_EMPLOYEES_RANGE_STRING")) + " (" + _toDisplayString(_ctx.companyYearlyParams.azienda_yearly_parameters.year) + ")", 1),
              _createElementVNode("div", _hoisted_32, [
                (_ctx.companyYearlyParams.employees_range)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.companyYearlyParams.employees_range.description), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.translate("AZIENDA_COMPANY_TYPE_STRING")) + " (" + _toDisplayString(_ctx.companyYearlyParams.azienda_yearly_parameters.year) + ")", 1),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("span", _hoisted_37, [
                  (_ctx.companyYearlyParams.company_type == 'D01')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.translate("CHANGE_PMI_OPTION")), 1)
                      ], 64))
                    : (_ctx.companyYearlyParams.company_type == 'D02')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.translate("CHANGE_SMALL_OPTION")), 1)
                        ], 64))
                      : (_ctx.companyYearlyParams.company_type == 'D03')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(_toDisplayString(_ctx.translate("CHANGE_LARGE_OPTION")), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_38, [
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("h3", _hoisted_41, _toDisplayString(_ctx.translate("USER_DETAIL_SEC_HEADER")), 1)
        ]),
        _createVNode(_component_router_link, {
          to: "/pages/account/settings",
          class: "btn btn-dark-blue align-self-center"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("HEADER_EDIT_ACCOUNT_SETTINGS_BUTTON")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("div", _hoisted_43, [
          _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.translate("USER_ACCOUNT_USERNAME_STRING")), 1),
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.userProfile.user_name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.translate("USER_ACCOUNT_EMAIL_STRING")), 1),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.userProfile.email), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_51, [
          _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.translate("USER_COMPANY_ROLE_STRING")), 1),
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.userProfile.ruolo_aziendale), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.translate("USER_ACCOUNT_TELEPHONE_STRING")), 1),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.userProfile.telefono), 1)
          ])
        ])
      ])
    ])
  ]))
}